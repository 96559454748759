<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
         <v-row no-gutters v-if="$store.state.user">
            <v-col v-if="$store.state.settingMode" cols="auto" class="d-flex">
                <CreateActivityModal  class="mx-1" v-on:success="getActivity" v-on:update="getActivity"/>
            </v-col>
            <v-col cols="auto" class="d-flex ml-auto" align="right">
                <v-btn v-if="$store.state.settingMode" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="getActivity">
                    {{ $store.getters.getTextMap().refresh }}
                    <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().submitbtnColor" small>
                        mdi-refresh
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="my-1 my-2" no-gutters v-bind:key="activity.activity_id" v-for="activity in activities">
            <v-col>
                <v-card class=" " flat :style="{ 'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode,'background-color':$store.getters.getColorPalette().background2ColorCode}"  :dark="$store.getters.getColorPalette().isDark">
                    <v-card-title  :style="{background: $store.getters.getColorPalette().background2ColorCode, color:$store.getters.getColorPalette().accentCode}">
                        {{activity.name}}
                    </v-card-title>
                    <v-card-subtitle>
                        {{activity.msg}}
                    </v-card-subtitle>
                    <v-card-subtitle>
                       {{ $store.getters.getTextMap().escalation_matrix }} &nbsp; {{activity.em_name}}
                    </v-card-subtitle>
                    <v-col cols="auto" class="d-flex ml-auto" align="right">
                        <CreateActivityModal align="right" :activity="activity" class="pr-2 pb-2" v-on:success="getActivity" v-on:update="getActivity"/>
                        <DeleteConfirmation  v-on:confirm="deleteFromTable(activity)"  title="Delete Confirmation"  description="Are you sure you want to delete this Activity?">
                            <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().delete_activity }}<v-icon small color="red">mdi-delete</v-icon></v-btn>  
                        </DeleteConfirmation>
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import axios from 'axios'
// import TaskPanel from '@/components/panels/application/taskmanagment/TaskPanel.vue'
import CreateActivityModal from '@/components/modals/application/CreateActivityModal.vue'
export default {
    name:'ActivityPanel',
    components:{
        InfoAlert,
        CreateActivityModal,
        DeleteConfirmation
        // TaskPanel,
    },
    data(){
        return {
            editActivity:false,
            showDialog:false,
            loading:false,
            showEdit:true,
            info:'',
            showDismissibleAlert:false,
            activities:[],
            activity:null
        }
    },
    mounted(){
        this.getActivity()
    },
    methods:{
        showDetails(activity){
            this.activity=activity
            this.editActivity=!this.editActivity
        },
        getActivity(){
            this.activity=null
            this.loading=true
            axios.get(this.$store.state.api+'activities',{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
            if(response.data.status=='success'){
                this.edit=false
                this.activities=response.data.activities
                //console.log( response.data.activitiess,"jj")
            }else{
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
            this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        },
        deleteFromTable(item){
            axios.post(this.$store.state.api+'deleteActivity',{activity_id:item.activity_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
            if(response.data.status=='success'){
                this.edit=false
                this.getActivity()
            }else{
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
            this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        }
    },
}
</script>