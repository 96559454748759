<template>
    <div>
        <v-btn 
        v-if="mode==='Edit'"
        :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined 
            @click="dialog = !dialog"
        >
        {{ mode }} {{ $store.getters.getTextMap().em_level }}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-pencil</v-icon>
        </v-btn>
        <v-btn v-else
        :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined 
            @click="dialog = !dialog"
        >
        {{ mode }} {{ $store.getters.getTextMap().em_level }} <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-priority-high</v-icon>
        </v-btn>
        <v-dialog
            v-model="dialog"
            transition="dialog-bottom-transition"
        >
            <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ mode }}{{ $store.getters.getTextMap().em_level }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="dialog = false">{{ $store.getters.getTextMap().close }}</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <AddEMLevel :em="em" v-on:close="onClose" v-on:update="$emit('update')"/>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import AddEMLevel from '@/components/crud_components/application/taskmanagment/AddEMLevel'
export default {
    name:'AddEMLevelModal',
    props:['em'],
    components:{
        AddEMLevel  
    },
    data(){
        return {
            dialog:false,
            mode:this.$store.getters.getTextMap().edit,
        }
    },
    mounted(){
        if(this.em){
            this.mode = this.$store.getters.getTextMap().add
        }
    },
    methods:{
        onClose(){
            this.dialog=!this.dialog
            this.$emit('success')
        }
    }
}
</script>
<style scoped>
  .my-event {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;
    background-color: #1867c0;
    color: #ffffff;
    border: 1px solid #1867c0;
    font-size: 12px;
    padding: 3px;
    cursor: pointer;
    margin-bottom: 1px;
    left: 4px;
    margin-right: 8px;
    position: relative;
  }
    .with-time {
      position: absolute;
      right: 4px;
      margin-right: 0px;
    }
  
</style>
  