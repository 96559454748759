<template>
    <v-row :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode2" class="fill-height">
      <v-col>
        
        <v-sheet :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode" height="64">
          <v-toolbar
          :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}"
            flat
          >
            <v-btn
              outlined
              text
              small
              class="mr-4"
              :color=$store.getters.getColorPalette().accentCode
              @click="setToday"
            >
            {{$store.getters.getTextMap().today}}

            </v-btn>
            <v-btn
              fab
              text
              small
              :color=$store.getters.getColorPalette().accentCode
              @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
              fab
              text
              small
              :color=$store.getters.getColorPalette().accentCode
              @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu
              bottom
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                outlined
              text
                    small
                  :color=$store.getters.getColorPalette().accentCode
                  v-bind="attrs"
                  v-on="on"
                >
                  <span v-if='typeToLabel[type]=="Month"'>{{ $store.getters.getTextMap().month }}</span>
                  <span v-if='typeToLabel[type]=="Day"'>{{ $store.getters.getTextMap().day }}</span>
                  <span v-if='typeToLabel[type]=="Week"'>{{ $store.getters.getTextMap().week }}</span>
                  <span v-if='typeToLabel[type]=="4 Days"'>{{ $store.getters.getTextMap().t4_days }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>

              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>{{$store.getters.getTextMap().day}}
</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>{{$store.getters.getTextMap().week}}
</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>{{$store.getters.getTextMap().month}}
</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>{{$store.getters.getTextMap().days_4}}
</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
          :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}"
            ref="calendar"
            v-model="focus"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="getTasks"
            :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode2"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <TaskDetails v-if="showTaskDetails" :task="task" v-on:close="refreshTask"/>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </template>
<script>
import moment from 'moment'
import TaskDetails from '@/components/items/application/taskmanagment/TaskDetails.vue';
export default {
    components:{
        TaskDetails
    },
    
  data: () => ({
    focus: '',
    type: 'month',
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      '4day': '4 Days',
    },
    showTaskDetails:false,
    task:null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
  }),
  mounted () {
    this.$refs.calendar.checkChange()
   
  },
  methods: {
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
        for(let i of this.$store.state.tasks){
            if(i.task_id===event.task_id){
                this.task=i
                break
            }
        }
        this.showTaskDetails=!this.showTaskDetails
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    getTasks(){
        const events=[]
        for(let i of this.$store.state.tasks){
            events.push({
          name: i.name,
          start: moment(i.start_time).toDate(),
          end: moment(i.end_time).toDate(),
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: i.timed,
          task_id:i.task_id
        })
        }
        this.events=events

    },
    refreshTask(){
        this.showTaskDetails=false

            this.$emit('update')
        },
    // updateRange ({ start, end }) {
    //   const events = []

    //   const min = new Date(`${start.date}T00:00:00`)
    //   const max = new Date(`${end.date}T23:59:59`)
    //   const days = (max.getTime() - min.getTime()) / 86400000
    //   const eventCount = this.rnd(days, days + 20)

    //   for (let i = 0; i < eventCount; i++) {
    //     const allDay = this.rnd(0, 3) === 0
    //     const firstTimestamp = this.rnd(min.getTime(), max.getTime())
    //     const first = new Date(firstTimestamp - (firstTimestamp % 900000))
    //     const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
    //     const second = new Date(first.getTime() + secondTimestamp)

    //     events.push({
    //       name: this.names[this.rnd(0, this.names.length - 1)],
    //       start: first,
    //       end: second,
    //       color: this.colors[this.rnd(0, this.colors.length - 1)],
    //       timed: !allDay,
    //     })
    //   }

    //   this.events = events
    //   console.log(this.events)
    // },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
  },
}
</script>
