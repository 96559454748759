<template>
    <v-container fluid>
      <InfoAlert
        :showDismissibleAlert="showDismissibleAlert"
        v-on:close="showDismissibleAlert = false"
        :info="info"
      />
      <v-row no-gutters dense>
        <v-spacer></v-spacer>
        <v-col align="right" cols="2">
          <span>
          <v-switch
              v-model="showInsights"
              :label="$store.getters.getTextMap().insights"
              color="success"
              value="true"
              hint="Turn on to see insights"
              small
              
              :loading="loading"
              size="2"
            ></v-switch>
            </span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row  v-if="$store.state.user">
        <v-col>
          <v-card
            flat
            outlined
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().backgroundColorCode"
          >
            <v-data-table
              :headers="headers"
              :items="tableData"
              item-key="machine_id"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-class="font-weight-black text-center"
              :style="{
                background: $store.getters.getColorPalette().background2ColorCode,
                color: $store.getters.getColorPalette().accentCode
              }"
              :dark="$store.getters.getColorPalette().isDark"
            >
              <template v-slot:item.days_left="{ item }">
                <td >
                  <span :class="getDaysLeftColorClass(item.maintenance_date)+' black--text  pa-1 '"><strong>{{ getDaysLeft(item.maintenance_date) }}</strong></span>
                </td>
              </template>
              <template v-slot:item.insights="{ item }">
                <td :class="'    ma-1 pa-1'" >
                  <span ><strong>{{ item.insight }}</strong></span>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import InfoAlert from '@/components/InfoAlert';
import moment from 'moment';
  
  export default {
    name: 'MaintenancePanel',
    components: {
      InfoAlert,
    },
    data() {
      return {
        showDialog: false,
        loading: false,
        showEdit: true,
        info: '',
        showDismissibleAlert: false,
        // headers: [
        //   { text: 'Workspace', align: 'start', value: 'workspace' },
        //   { text: 'Machine', value: 'name' },
        //   { text: 'Maintenance Due On', value: 'maintenance_date' },
        //   { text: 'Days Left', value: 'days_left' },
        //   { text: 'Enture Insights', value: 'insights' },
        // ],
        showInsights:false,
        tableData: [
          { machine_id: '1', workspace: 'Incomer', name: 'Transformer 1', maintenance_date: '2024-04-02',insight_severity:1, insight:'Daily consumption has gone up by 10%. Health Check might be required.' },
          { machine_id: '2', workspace: 'HVAC', name: 'Chiller 1', maintenance_date: '2024-04-4',insight_severity:2, insight:'There is a slight decrease in average current consumption' },
          { machine_id: '3', workspace: 'HVAC', name: 'Chiller 2', maintenance_date: '2024-03-17',insight_severity:0, insight:'No Issues identified'  },
          { machine_id: '4', workspace: 'Manufacturing', name: 'Manufacturing & Packaging', maintenance_date: '2024-06-30', insight_severity:3,insight:'The current drawn has increased. Needs maintenance.' },
          { machine_id: '5', workspace: 'Main PCC', name: 'ETP', maintenance_date: '2024-12-03', insight_severity:0, insight:'No Issues identified' },
          { machine_id: '6', workspace: 'Compressors', name: 'Compressor 1', maintenance_date: '2024-02-03', insight_severity:1, insight:'Maintenance Date has passed. But there is no change in performance.' },
        ],
      };
    },
    computed:{
      headers(){
        let headers=[
          { text: this.$store.getters.getTextMap().workspace, align: 'start', value: 'workspace' },
          { text: this.$store.getters.getTextMap().machine, value: 'name' },
          { text: this.$store.getters.getTextMap().maintenance_due_on, value: 'maintenance_date' },
          { text: this.$store.getters.getTextMap().days_left, value: 'days_left' },
          
        ]
        if(this.showInsights){
          headers.push({ text: this.$store.getters.getTextMap().enture_insights, value: 'insights' },)
        }
        return headers
      }
    },
    methods: {
      getDaysLeft(timestmp){
        return moment(timestmp).fromNow()
      },
      getSeverityColor(severity){
        if (severity >2) {
          return 'red lighten-3';
        } else if (severity == 2) {
          return 'orange lighten-3';
        } else {
          return 'green lighten-3';
        }
      },
      getDaysLeftColorClass(timestmp) {
        let daysLeft=moment.duration(moment(timestmp).diff(moment())).asDays()
        // console.log(daysLeft)
        if (daysLeft <= 5) {
          return 'red lighten-3';
        } else if (daysLeft <= 15) {
          return 'orange lighten-3';
        } else {
          return 'green lighten-3';
        }
      },
    },
  };
  </script>
  
  <style>
  .days-left-critical {
    color: rgb(241, 68, 68);
    /* background-color: rgb(117, 117, 228); */
  }
  
  .days-left-warning {
    color: orange;
    /* background-color: rgb(117, 117, 228); */
  }
  
  .days-left-normal {
    color: #1DE9B6;
    /* background-color: rgb(99, 189, 245); */
  }
  </style>
  