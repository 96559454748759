<!-- <template>
    <div>
        <v-container class="pa-1" v-if="$store.state.user">
            <v-row>
                <v-col align="left" align-self="center">
                    <v-btn 
                        :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined 
                        @click="showCalendar = !showCalendar"
                    >
                    Calendar<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-calendar-blank</v-icon>
                    </v-btn>
                </v-col>
                <v-col align="center" align-self="center">
                    <div>
                        <h1 class="heading">Task Managment</h1>
                    </div>
                </v-col>
                <v-col align="right" align-self="center">
                    <CreateActivityModal v-if="$store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) "/>
                </v-col>
            </v-row>
        </v-container>
        <v-row v-if="showCalendar">
            <v-col>
                <TaskCalendarPanel/>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-sheet>
                    <v-tabs
                        :dark="$store.getters.getColorPalette().isDark"
                        :background-color="$store.getters.getColorPalette().tab"
                        show-arrows
                        v-model="tab"
                    >
                        <v-tabs-slider :color="$store.getters.getColorPalette().inputtext2Color"></v-tabs-slider>
                        <router-link  v-for="activity in $store.state.activities" :key="activity.activity_id"  :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="`/taskmanagment/${activity.activity_id}`">
                            <v-tab>{{activity.name}}</v-tab>
                        </router-link>
                    </v-tabs>
                </v-sheet>
            </v-col>
        </v-row>
        <v-row v-if="$store.state.areas.length==0">
            <v-col align="center">
                <v-icon large :color="$store.getters.getColorPalette().accentCode">mdi-hail<v-spacer></v-spacer></v-icon>
                <h3 :style="{color:$store.getters.getColorPalette().accentCode}" >Not Configured</h3>
            </v-col>
        </v-row>
        <router-view></router-view>
    </div>
</template>
<script>
import CreateActivityModal from '@/components/modals/application/CreateActivityModal'
import TaskCalendarPanel from '@/components/panels/application/taskmanagment/TaskCalendarPanel.vue'
export default {
    name:'TaskManagment',
    components:{
        CreateActivityModal,
        TaskCalendarPanel,
    },
    data(){
        return {
            tab:null,
            showCalendar:false
        }
    },
    mounted(){
        document.title='Task Managment'
        if (!this.$store.state.loggedIn) {
            this.$router.push("/");
        }
        if (this.$store.state.activities && this.$store.state.activities.length > 0) {
            const firstActivityId = this.$store.state.activities[0].activity_id;
            this.$router.push(`/taskmanagment/${firstActivityId}`);
        }
    },
}
</script>
<style scoped>
.heading {
  font-family: 'Helvetica Neue', sans-serif;
  font-size:20px;
  color: #FFB74D;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
</style> -->

<template>
    <v-container fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <!-- <v-row no-gutters v-if="$store.state.user">
            <v-col cols="auto" class="d-flex mr-auto" align="left">
                <v-btn 
                class="mx-1 mb-2"
                    :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined 
                    @click="showCalendar = !showCalendar"
                >
                Calendar<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-calendar-blank</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="mb-2" no-gutters v-if="showCalendar">
            <v-col>
                <v-divider :color="$store.getters.getColorPalette().panelBorderColorCode" class="my-3"></v-divider>
                <TaskCalendarPanel v-on:update="getTask"/>
            </v-col>
        </v-row> -->
        <v-row no-gutters>
            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
            <v-col>        
                    <v-tabs
                        :dark="$store.getters.getColorPalette().isDark"
                        :background-color="$store.getters.getColorPalette().backgroundColorCode"
                        show-arrows
                        
                        v-model="tab"
                    >
                        <v-tabs-slider :color="$store.getters.getColorPalette().accentCode"></v-tabs-slider>
                        <v-tab><v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-developer-board</v-icon>{{ $store.getters.getTextMap().board }}</v-tab>
                        <v-tab><v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-file-tree</v-icon>{{ $store.getters.getTextMap().activities }}</v-tab>
                        <v-tab><v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mx-2' small>mdi-calendar</v-icon>{{ $store.getters.getTextMap().calender }}</v-tab>
                        <v-tab><v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mx-2' small>mdi-priority-high</v-icon>{{ $store.getters.getTextMap().escalation_matrix }}</v-tab>
                        <v-tab><v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mx-2' small>mdi-wrench-clock</v-icon>{{ $store.getters.getTextMap().maintenance }}</v-tab>
                    </v-tabs>
                    <v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider>
                    <v-tabs-items :dark="$store.getters.getColorPalette().isDark"  v-model="tab">
                        <v-tab-item :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}">
                            <BoardPanel/>
                        </v-tab-item>
                        <v-tab-item :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}">
                            <ActivityPanel/>
                        </v-tab-item>
                        <v-tab-item :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}">
                            <TaskCalendarPanel v-on:update="getTask"/>
                        </v-tab-item>
                        <v-tab-item :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}">
                            <EscalationMatrixPanel/>
                        </v-tab-item>
                        <v-tab-item :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}">
                            <MaintenancePanel/>
                        </v-tab-item>
                        </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
// import DeleteConfirmation from '@/components/DeleteConfirmation'
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import TaskCalendarPanel from '@/components/panels/application/taskmanagment/TaskCalendarPanel.vue'
import BoardPanel from '@/components/panels/application/taskmanagment/BoardPanel.vue'
import ActivityPanel from '@/components/panels/application/taskmanagment/ActivityPanel.vue'
import MaintenancePanel from '@/components/panels/application/taskmanagment/MaintenancePanel.vue'
import EscalationMatrixPanel from '@/components/panels/application/taskmanagment/EscalationMatrixPanel.vue'
export default {
    name:'TaskManagment',
    components:{
        InfoAlert,
        // DeleteConfirmation,
        TaskCalendarPanel,
        BoardPanel,
        ActivityPanel,
        MaintenancePanel,
        EscalationMatrixPanel
    },
    data(){
        return {
            tab:null,
            showDialog:false,
            loading:false,
            showEdit:true,
            info:'',
            showDismissibleAlert:false,
            activity_id:null,
            activity:null,
            tasks:null,
            showCalendar:false
        }
    },
    methods:{
        getTask(){
            this.tasks=null
            this.loading=true
            axios.get(this.$store.state.api+'tasks',{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
            if(response.data.status=='success'){
                this.edit=false
                this.tasks=response.data.tasks
            }else{
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
            this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        },
        deleteActivity(){
            let payload={activity_id:this.activity_id}
            this.loading=true
            axios.post(this.$store.state.api+'deleteActivity',payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }}).then(response=>{
            if(response.data.status==='success'){
                this.$store.dispatch('refreshActivities')
                if (this.$store.state.activities.length === 0) {
                    this.$router.push("/tasksmanagment");
                } else {
                    this.$router.push("/tasksmanagment/" + this.$store.state.activities[0].activity_id);
                }
            }
            })
            .catch(error=>{
                    this.loading=false
                    this.info=error
                    this.showDismissibleAlert=true
            });
        }
    },
}
</script>
<style scoped>
.heading {
  font-family: 'Helvetica Neue', sans-serif;
  font-size:20px;
  color: #FFB74D;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
</style>