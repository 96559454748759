<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row no-gutters v-if="$store.state.user">
            <v-col v-if="$store.state.settingMode" cols="auto" class="d-flex">
                <CreateEscalationMatrixModal  class="mx-1" v-on:success="getEscalationMatrix" v-on:update="getEscalationMatrix"/>
            </v-col>
            <v-col cols="auto" class="d-flex ml-auto" align="right">
                <v-btn v-if="$store.state.settingMode" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="getEscalationMatrix">
                   {{ $store.getters.getTextMap().refresh }}
                    <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().submitbtnColor" small>
                        mdi-refresh
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="my-1 my-2" no-gutters v-bind:key="em.em_id" v-for="em in escalation_matrix">
            <v-col>
                <v-card class=" " flat :style="{ 'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode,'background-color':$store.getters.getColorPalette().background2ColorCode}"  :dark="$store.getters.getColorPalette().isDark">
                    <v-card-title  :style="{background: $store.getters.getColorPalette().background2ColorCode, color:$store.getters.getColorPalette().accentCode}">
                        {{em.name}}
                    </v-card-title>
                    <v-card-subtitle>
                        {{em.msg}}
                    </v-card-subtitle>
                    <EMLevelDetails :em="em" v-on:update="getEscalationMatrix"/>
                    <v-col cols="auto" class="d-flex ml-auto" align="right">
                        <CreateEscalationMatrixModal :em="em" class="pr-2 pb-2" v-on:success="getEscalationMatrix" v-on:update="getEscalationMatrix"/>
                        <AddEMLevelModal :em="em" class="pr-2 pb-2" v-on:success="getEscalationMatrix" v-on:update="getEscalationMatrix"/>
                        <DeleteConfirmation  v-on:confirm="deleteFromTable(em)"  title="Delete Confirmation"  description="Are you sure you want to delete this Escalation Matrix?">
                            <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().delete }}<v-icon small color="red">mdi-delete</v-icon></v-btn>  
                         </DeleteConfirmation>
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import axios from 'axios'
// import TaskPanel from '@/components/panels/application/taskmanagment/TaskPanel.vue'
import CreateEscalationMatrixModal from '@/components/modals/application/taskmanagment/CreateEscalationMatrixModal.vue'
import AddEMLevelModal from '@/components/modals/application/taskmanagment/AddEMLevelModal.vue'
import EMLevelDetails from '@/components/items/application/taskmanagment/EMLevelDetails.vue';
export default {
    name:'EscalationMatrixPanel',
    components:{
        InfoAlert,
        CreateEscalationMatrixModal,
        AddEMLevelModal,
        EMLevelDetails,
        DeleteConfirmation
        // TaskPanel,
    },
    data(){
        return {
            showDialog:false,
            dialog:false,
            loading:false,
            showEdit:true,
            info:'',
            showDismissibleAlert:false,
            activity_id:null,
            activity:null,
            escalation_matrix:[],
            showCalendar:false,
        }
    },
    mounted(){
        this.getEscalationMatrix()
    },
    methods:{
        getEscalationMatrix(){
            this.escalation_matrix=null
            this.loading=true
            axios.get(this.$store.state.api+'escalationMatrix',{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
            if(response.data.status=='success'){
                this.edit=false
                this.escalation_matrix=response.data.escalation_matrix
            }else{
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
            this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        },
        deleteFromTable(item){
            axios.post(this.$store.state.api+'deleteEscalationMatrix',{em_id:item.em_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
            if(response.data.status=='success'){
                this.edit=false
                this.getEscalationMatrix()
            }else{
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
            this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        }
    },
}
</script>