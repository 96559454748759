<template>
    <v-container  >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form ref="escalationMatrixForm" v-model="valid"          >
            <v-row>
                <v-col align-self="center">
                    <v-text-field dense outlined v-if="!loading" v-model="form.name" :counter="45" :rules="[rules.required,rules.validString,rules.size(45)]" :label="$store.getters.getTextMap().name" required></v-text-field>
                </v-col>
                <v-col align-self="center">
                    <v-text-field dense outlined v-if="!loading" v-model="form.msg" :counter="200"  :label="$store.getters.getTextMap().description" required></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="right">
                    <v-btn :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="onSubmit">{{ $store.getters.getTextMap().submit }}</v-btn>
                    <v-btn style="margin-left:20px;"  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="close">{{ $store.getters.getTextMap().cancel }}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container> 
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
export default {
    name:'CreateEscalationMatrix',
    props:['em'],
    components:{
        InfoAlert
    },
    mounted(){
        if(this.em && this.em.em_id){
            this.form=Object.assign({},this.em)
            this.edit=true
            this.api='updateEscalationMatrix'
        }else{
            this.edit=false
            this.api='createEscalationMatrix'
        }
    },
    data(){
        return {
            edit:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            valid:false,
            api:'createEscalationMatrix',
            rules:FormRules.rules,
            form:{
                name:null,
                meta:null,
                msg:null, 
            },
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
            ],
            msgRules: [
                v => !!v || 'Description is required',
                v => (v && v.length <= 200 && v.length > 0) || 'Description must be less than 55 characters',
            ],
            selectRules:[
                v=> (v!=null) || 'Required'
            ],
            objectRules:FormRules.objectRules,
            numberRules:FormRules.numberRulesDynamic(6)
        }
    },
    methods:{
        onSubmit(){
            this.$refs.escalationMatrixForm.validate()
            if(this.valid){
                this.loading=true
                axios.post(this.$store.state.api+this.api,this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        // this.$store.dispatch('refreshEscalationMatrix')
                        this.$emit('update')
                        this.close()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            }
        },
        close(){
            this.$emit('close')
        }
    }
}
</script>