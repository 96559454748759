var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('InfoAlert',{attrs:{"showDismissibleAlert":_vm.showDismissibleAlert,"info":_vm.info},on:{"close":function($event){_vm.showDismissibleAlert = false}}}),_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-spacer'),_c('v-col',{attrs:{"align":"right","cols":"2"}},[_c('span',[_c('v-switch',{attrs:{"label":_vm.$store.getters.getTextMap().insights,"color":"success","value":"true","hint":"Turn on to see insights","small":"","loading":_vm.loading,"size":"2"},model:{value:(_vm.showInsights),callback:function ($$v) {_vm.showInsights=$$v},expression:"showInsights"}})],1)])],1),_c('v-divider'),(_vm.$store.state.user)?_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":"","outlined":"","dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().backgroundColorCode}},[_c('v-data-table',{style:({
            background: _vm.$store.getters.getColorPalette().background2ColorCode,
            color: _vm.$store.getters.getColorPalette().accentCode
          }),attrs:{"headers":_vm.headers,"items":_vm.tableData,"item-key":"machine_id","loading":_vm.loading,"loading-text":"Loading... Please wait","item-class":"font-weight-black text-center","dark":_vm.$store.getters.getColorPalette().isDark},scopedSlots:_vm._u([{key:"item.days_left",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('span',{class:_vm.getDaysLeftColorClass(item.maintenance_date)+' black--text  pa-1 '},[_c('strong',[_vm._v(_vm._s(_vm.getDaysLeft(item.maintenance_date)))])])])]}},{key:"item.insights",fn:function(ref){
          var item = ref.item;
return [_c('td',{class:'    ma-1 pa-1'},[_c('span',[_c('strong',[_vm._v(_vm._s(item.insight))])])])]}}],null,false,3457068472)})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }