<template>
    <v-container fluid>
      <InfoAlert
        :showDismissibleAlert="showDismissibleAlert"
        v-on:close="showDismissibleAlert = false"
        :info="info"
      />
      <v-row no-gutters v-if="$store.state.user">
        <v-col>
            <v-row v-if="!loading && em_level">
                <v-col cols="12">
                <v-simple-table :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
                    <template v-slot:default>
                    <thead :style="{background: $store.getters.getColorPalette().tableHeaderColorCode}">
                        <tr>
                        <th class="text-left">
                            <strong>{{$store.getters.getTextMap().user_group_Id}}</strong>
                        </th>
                        <th class="text-left">
                            <strong>{{$store.getters.getTextMap().user_group_name}}</strong>
                        </th>
                        <th class="text-left">
                            <strong>{{$store.getters.getTextMap().delay_in_mins}}</strong>
                        </th>
                        <th class="text-left">
                            <strong>{{$store.getters.getTextMap().action}}</strong>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        :key="index" v-for="(item,index) in em_level"
                        >
                        <td>{{ item.user_group_id }}</td>
                        <td>{{ item.user_group_name }}</td>
                        <td>{{ item.delay }}</td>
                        
                        <td>
                         <DeleteConfirmation  v-on:confirm="deleteFromTable(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this EM Level?">
                           <v-icon small color="red">mdi-delete</v-icon>  
                         </DeleteConfirmation>
                      </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                </v-col>
            </v-row>
          <!-- <v-dialog
            v-model="dialog"
            transition="dialog-bottom-transition"
        >
            <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Edit EM Level</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="dialog = false">close</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <AddEMLevel :em="em_details" v-on:close="onClose" v-on:update="$emit('update')"/>
            </v-card>
        </v-dialog> -->
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import InfoAlert from '@/components/InfoAlert';
  import DeleteConfirmation from '@/components/DeleteConfirmation'
//   import AddEMLevel from '@/components/crud_components/application/taskmanagment/AddEMLevel'
  import axios from 'axios'
  export default {
    name: 'EMLevelDetails',
    props:['em'],
    components: {
      InfoAlert,
      DeleteConfirmation
    //   AddEMLevel,
    },
    data() {
      return {
        showDialog: false,
        dialog:false,
        loading: false,
        showEdit: true,
        info: '',
        showDismissibleAlert: false,
        em_details:null,
        editEMLevel:false,
        em_level:[],
        headers: [
                { text: 'User Group Id', align: 'start', value: 'user_group_id' },
                { text: 'Delay (In Minutes)', value: 'delay' },
            ],
      };
    },
    mounted(){
        if(this.em && this.em.em_id){
            this.getEscalationMatrixLevel()
        }
    },
    methods: {
        EditEMLevel(item){
            this.em_details=null
            this.em_details=item
            this.dialog=!this.dialog
        },
        getEscalationMatrixLevel(){
            this.em_level=[]
            this.loading=true
            axios.post(this.$store.state.api+'getEMLevelByEMId',{em_id:this.em.em_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
            if(response.data.status=='success'){
                this.edit=false
                this.em_level=response.data.escalation_matrix_level
            }else{
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
            this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        },
        onClose(){
            this.dialog=!this.dialog
            this.$emit('success')
        },
        deleteFromTable(item){
            axios.post(this.$store.state.api+'deleteEMLevel',{em_id:item.em_id,user_group_id:item.user_group_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
            if(response.data.status=='success'){
                this.edit=false
                this.$emit('update')
            }else{
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
            this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        }
    },
  };
  </script>
  
  <style>
  .days-left-critical {
    color: red;
  }
  
  .days-left-warning {
    color: orange;
  }
  
  .days-left-normal {
    color: #1DE9B6;
  }
  </style>
  