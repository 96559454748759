<template>
    <v-container  >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form ref="emLevelForm" v-model="valid"          >
            <v-row>
                <v-col align-self="center">
                    <v-text-field dense outlined v-if="!loading" v-model="form.msg" :counter="200"  :label="$store.getters.getTextMap().description" required></v-text-field>
                </v-col>
                <v-col align-self="center">
                    <v-select
                        :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="form.delay"
                        :items="delayOptions"
                        :label="$store.getters.getTextMap().delay"
                        outlined
                        dense
                        item-text="label"
                        item-value="value"
                        required
                        :rules="selectRules"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-autocomplete
                        v-if="!loading"
                        v-model="form.user_group_id"
                        :items="userGroupOptions"
                        :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        outlined
                        dense
                        :rules="objectRules"
                        :label="$store.getters.getTextMap().user_group"
                        item-text="label"
                        item-value="value"
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="right">
                    <v-btn :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="onSubmit">{{ $store.getters.getTextMap().submit  }}</v-btn>
                    <v-btn style="margin-left:20px;"  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="close">{{ $store.getters.getTextMap().cancel  }}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container> 
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
export default {
    name:'AddEMLevel',
    props:['em'],
    components:{
        InfoAlert
    },
    mounted(){
        if(this.em && this.em.em_id){
            this.form.em_id=this.em.em_id
            this.form.user_group_id=this.em.user_group_id
            this.form.msg=this.em.msg
            this.edit=true
            this.api='addEMLevel'
        }
    },
    data(){
        return {
            edit:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            valid:false,
            api:'addEMLevel',
            form:{
                em_id:null,
                delay:30,
                user_group_id:null,
                meta:null,
                msg:null, 
            },
            delayOptions:[
                {label:this.$store.getters.getTextMap().mins_5, value:5},
                {label:this.$store.getters.getTextMap().mins_10, value:10},
                {label:this.$store.getters.getTextMap().mins_15, value:15},
                {label:this.$store.getters.getTextMap().mins_30, value:30},
                {label:this.$store.getters.getTextMap().hour_1, value:60},
                {label:this.$store.getters.getTextMap().hour_6, value:360},
                {label:this.$store.getters.getTextMap().day_1, value:1440},
                {label:this.$store.getters.getTextMap().day_3, value:4320},
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
            ],
            msgRules: [
                v => !!v || 'Description is required',
                v => (v && v.length <= 200 && v.length > 0) || 'Description must be less than 55 characters',
            ],
            selectRules:[
                v=> (v!=null) || 'Required'
            ],
            objectRules:FormRules.objectRules,
            numberRules:FormRules.numberRulesDynamic(6)
        }
    },
    computed:{
        userGroupOptions(){
            let op=[]
              let g=this.$store.state.userGroups
              for(let i of g){
                  op.push( {value:i.group_id, label: i.name })
                }
          return op
        },
    },
    methods:{
        onSubmit(){
            this.$refs.emLevelForm.validate()
            if(this.valid){
                this.loading=true
                axios.post(this.$store.state.api+this.api,this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.$emit('update')
                        this.close()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            }
        },
        close(){
            this.$emit('close')
        }
    }
}
</script>