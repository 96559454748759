<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row no-gutters v-if="$store.state.user">
            <v-col v-if="$store.state.settingMode" cols="auto" class="d-flex">
                <CreateTaskModal  class="mx-1" v-on:success="getTask" v-on:update="getTask"/>
            </v-col>
            <v-col cols="auto" class="d-flex ml-auto" align="right">
                <v-btn v-if="$store.state.settingMode" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="getTask">
                   {{ $store.getters.getTextMap().refresh }}
                    <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>
                        mdi-refresh
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row  no-gutters>
            <v-col>
                <TaskPanel :activity="activity" :tasks="tasks" v-on:update="getTask"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import TaskPanel from '@/components/panels/application/taskmanagment/TaskPanel.vue'
import CreateTaskModal from '@/components/modals/application/taskmanagment/CreateTaskModal.vue'
export default {
    name:'BoardPanel',
    components:{
        InfoAlert,
        CreateTaskModal,
        TaskPanel,
    },
    data(){
        return {
            showDialog:false,
            loading:false,
            showEdit:true,
            info:'',
            showDismissibleAlert:false,
            activity_id:null,
            activity:null,
            tasks:[],
            showCalendar:false
        }
    },
    mounted(){
        this.getTask()
    },
    methods:{
        getTask(){
            this.tasks=[]
            this.loading=true
            axios.get(this.$store.state.api+'tasks',{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
            if(response.data.status=='success'){
                this.edit=false
                this.tasks=response.data.tasks
            }else{
                this.info=response.data.msg
                this.showDismissibleAlert=true
            }
            this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        },
    },
}
</script>